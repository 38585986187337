import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavBar from '../components/NavBar';
import SelectedWork from '../components/homepage/SelectedWork';
import Contact from '../components/Contact';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
  },
  titleContainer: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    top: 180,
  },
  stripes: {
    textAlign: 'center',
    padding: 0,
    margin: 0,
    height: '30vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
  },
  stripesRight: {
    padding: 0,
    margin: 0,
    bottom: 25,
    height: '15vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
    zIndex: -10,
  },
  font: {
    fontFamily: 'Roboto Condensed',
    textTransform: 'uppercase',
    fontWeight: 500,
    textAlign: 'center',
  },
  center: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function Index() {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="xl">
        <Box my={5}>
          <NavBar />
        </Box>
        <Box position="relative" m={0} p={0} maxWidth="100vw" maxHeight="75vh">
          <Box position="absolute" className={classes.center}>
            <Typography variant="h2" className={classes.font}>
              Recent Projects
            </Typography>
          </Box>
          <Box m={0} p={0} maxWidth="100vw" className={classes.stripes}></Box>
        </Box>
        <Box mb={7}>
          <SelectedWork />
        </Box>
        <Box className={classes.stripesRight}></Box>
      </Container>
      <Box mt={5}>
        <Contact />
      </Box>
    </>
  );
}
